'use client';

import isArticleCoverColor from '@haaretz/s-type-predicates/isArticleCoverColor';
import React from 'react';

import type { ArticleCoverColor } from '@haaretz/s-types';

const ArticleCoverColorContext = React.createContext<ArticleCoverColor | null | undefined>(
  undefined
);
const ArticleCoverColorSetter = React.createContext<
  ((value?: string | null | undefined) => void) | undefined
>(undefined);

/**
 * NOTE: It's very important to import getMatchMediaString not from the root of the `@haaretz/l-theme-shared` package,
 * because we don't want to include all the package code into the client bundle.
 */
export function ArticleCoverColorProvider({
  children,
  value: defaultArticleCoverColor,
}: React.PropsWithChildren<{ value: ArticleCoverColor | null }>) {
  const [articleCoverColor, setArticleCoverColor] = React.useState<ArticleCoverColor | null>(
    defaultArticleCoverColor
  );

  const articleCoverColorSetter = React.useCallback((value?: string | undefined | null) => {
    setArticleCoverColor(isArticleCoverColor(value) ? value : null);
  }, []);

  return (
    <ArticleCoverColorContext value={articleCoverColor}>
      <ArticleCoverColorSetter value={articleCoverColorSetter}>{children}</ArticleCoverColorSetter>
    </ArticleCoverColorContext>
  );
}

export default function useArticleCoverColor() {
  const context = React.use(ArticleCoverColorContext);

  if (context === undefined) {
    throw new Error('useArticleCoverColor must be used within a ArticleCoverColorProvider');
  }

  return context;
}

export function useSetArticleCoverColor() {
  const context = React.use(ArticleCoverColorSetter);

  if (context === undefined) {
    throw new Error('useSetArticleCoverColor must be used within a ArticleCoverColorProvider');
  }

  return context;
}

export function useArticleCoverColorAtom() {
  return [useArticleCoverColor(), useSetArticleCoverColor()] as const;
}
