'use client';

import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageSetterItem from '@haaretz/s-browser-storage-utils/setItem';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import { USER_READING_HISTORY_COUNT } from '@haaretz/s-consts';
import React from 'react';

import useArticleId from '../articleId';

const READING_HISTORY_COUNT_INITIAL_VALUE = 0;

export const getInitialReadingHistoryCount = () => {
  const item = browserStorageGetItem({ key: USER_READING_HISTORY_COUNT, storage: 'local' });
  if (item) {
    return Number.parseInt(item) || READING_HISTORY_COUNT_INITIAL_VALUE;
  }
  return READING_HISTORY_COUNT_INITIAL_VALUE;
};

const ReadingHistoryCountContext = React.createContext<number | undefined>(undefined);

export function ReadingHistoryCountProvider({ children }: React.PropsWithChildren) {
  const articleId = useArticleId();

  const [readingHistoryCount, setReadingHistoryCount] = React.useState(
    getInitialReadingHistoryCount()
  );

  const onPathChangedCb = React.useCallback(() => {
    if (articleId) {
      setReadingHistoryCount(prev => {
        const updatedReadingHistoryCount = prev + 1;

        browserStorageSetterItem({
          key: USER_READING_HISTORY_COUNT,
          value: updatedReadingHistoryCount,
          storage: 'local',
        });

        return updatedReadingHistoryCount;
      });
    }
  }, [articleId]);

  usePathChanged(onPathChangedCb);

  return (
    <ReadingHistoryCountContext value={readingHistoryCount}>{children}</ReadingHistoryCountContext>
  );
}

export default function useReadingHistoryCount() {
  const context = React.use(ReadingHistoryCountContext);

  if (context === undefined) {
    throw new Error('useReadingHistoryCount must be used within a ReadingHistoryCountProvider');
  }

  return context;
}
