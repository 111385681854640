'use client';

import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageSetterItem from '@haaretz/s-browser-storage-utils/setItem';
import { USER_READING_HISTORY } from '@haaretz/s-consts';
import React from 'react';

import useArticleId from '../articleId';

type ReadingHistory = string[];

const READING_HISTORY_COUNT_INITIAL_VALUE: ReadingHistory = [];

export const getInitialReadingHistory = () => {
  const item = browserStorageGetItem({ key: USER_READING_HISTORY, storage: 'local' });
  if (item) {
    try {
      return JSON.parse(item) as ReadingHistory;
    } catch (e) {
      console.error(`Error parsing reading history: ${(e as Error).message}}`);
      return READING_HISTORY_COUNT_INITIAL_VALUE;
    }
  }
  return READING_HISTORY_COUNT_INITIAL_VALUE;
};

const ReadingHistoryContext = React.createContext<ReadingHistory | undefined>(undefined);

export function ReadingHistoryProvider({ children }: React.PropsWithChildren) {
  const articleId = useArticleId();

  const [readingHistory, setReadingHistory] = React.useState<ReadingHistory>(
    getInitialReadingHistory()
  );

  React.useEffect(() => {
    if (articleId) {
      setReadingHistory(prev => {
        const updateReadingHistory = [...new Set([articleId, ...prev])];

        if (updateReadingHistory.length > 50) updateReadingHistory.length = 50;

        browserStorageSetterItem({
          key: USER_READING_HISTORY,
          value: updateReadingHistory,
          storage: 'local',
        });

        return updateReadingHistory;
      });
    }
  }, [articleId]);

  return <ReadingHistoryContext value={readingHistory}>{children}</ReadingHistoryContext>;
}

export default function useReadingHistory() {
  const context = React.use(ReadingHistoryContext);

  if (context === undefined) {
    throw new Error('useReadingHistory must be used within a ReadingHistoryProvider');
  }

  return context;
}
