'use client';

import React from 'react';

import useUser from '../user';

const ZEN_MODE_PERSISTENT_STORAGE_KEY = 'htz-zen-mode-value';

const ZenModeContext = React.createContext<boolean | undefined>(undefined);
const ZenModeSetterContext = React.createContext<((value?: false) => void) | undefined>(undefined);
export function ZenModeProvider({ children }: React.PropsWithChildren) {
  const [zenMode, setZenMode] = React.useState<boolean>(false);

  const user = useUser('cookieValue');

  const zenModeSetter = React.useCallback(
    (value?: false) => {
      if (user.userType === 'paying') {
        setZenMode(prev => {
          const updateZenMode = typeof value === 'boolean' ? value : !prev;

          sessionStorage.setItem(ZEN_MODE_PERSISTENT_STORAGE_KEY, updateZenMode.toString());

          return updateZenMode;
        });
      } else if (value !== false) {
        setZenMode(false);
      }
    },
    [user.userType]
  );

  React.useEffect(() => {
    let persistantValue = false;

    try {
      if (user.userType === 'paying') {
        persistantValue = JSON.parse(
          sessionStorage.getItem(ZEN_MODE_PERSISTENT_STORAGE_KEY) ?? 'false'
        ) as boolean;
      }
    } catch (e) {
      /* istanbul ignore next */
      console.error(e);
    }

    /* istanbul ignore next */
    if (persistantValue) {
      setZenMode(persistantValue);
    }
  }, [user.userType]);

  return (
    <ZenModeContext value={zenMode}>
      <ZenModeSetterContext value={zenModeSetter}>{children}</ZenModeSetterContext>
    </ZenModeContext>
  );
}

export default function useZenMode() {
  const context = React.use(ZenModeContext);

  if (context === undefined) {
    throw new Error('useZenMode must be used within a ZenModeProvider');
  }

  return context;
}

export function useZenModeSetter() {
  const context = React.use(ZenModeSetterContext);

  if (context === undefined) {
    throw new Error('useZenModeSetter must be used within a ZenModeProvider');
  }

  return context;
}

export function useZenModeAtom() {
  return [useZenMode(), useZenModeSetter()] as const;
}
