'use client';

import React from 'react';

import type { ImmutablePageDataFragment } from '@haaretz/s-fragments/ImmutablePageData';

export type PageAtomType = {
  pageContentId: ImmutablePageDataFragment['contentId'];
  articleSectionId?: string;
} | null;

const PageContext = React.createContext<PageAtomType | undefined>(undefined);
const PageSetter = React.createContext<((value: PageAtomType) => void) | undefined>(undefined);

export function PageProvider({
  children,
  value: defaultPage,
}: React.PropsWithChildren<{ value: PageAtomType }>) {
  const [page, setPage] = React.useState<PageAtomType>(defaultPage);

  const pageSetter = React.useCallback((value: PageAtomType) => {
    setPage(value);
  }, []);

  return (
    <PageContext value={page}>
      <PageSetter value={pageSetter}>{children}</PageSetter>
    </PageContext>
  );
}

export default function usePage() {
  const context = React.use(PageContext);

  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }

  return context;
}

export function useSetPage() {
  const context = React.use(PageSetter);

  if (context === undefined) {
    throw new Error('useSetPage must be used within a PageProvider');
  }

  return context;
}

export function usePageAtom() {
  return [usePage(), useSetPage()] as const;
}
